import React from "react";
import ReactDOM from "react-dom";
import LessonPage from "./components/lesson/LessonPage";
// import LessonsPage from "./components/lessonlist/LessonsPage";
import SlidePage from "./components/slide/SlidePage";

import './style/index.scss';

(function () {
    const urlParams = new URLSearchParams(window.location.search);
    const lesson = urlParams.get('lesson');
    const slide = urlParams.get('slide');
    const mode = urlParams.get('mode');

    const rootEl = document.createElement('div')
    document.body.prepend(rootEl)

    if( slide ) {
        ReactDOM.render(<SlidePage slide={slide} />, rootEl)
    } else if (lesson) {
        ReactDOM.render(<LessonPage lesson={lesson} mode={mode} />, rootEl)
    } else {
        //ReactDOM.render(<LessonsPage />, rootEl)
    }
})();
