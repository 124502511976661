import React, { useState } from 'react';
import { getSlide, SlideSoort } from '../../services/backend';
import AsyncContent from '../common/AsyncContent';
import SlideView from './SlideView';

import './SlidePage.scss';

const SlidePage = ({slide}) => {

    const [popupContent, setPopupContent] = useState();

    const slideLoadingPromise = slide ? getSlide(slide) : null;
    const _slideField = { };
    if( slide ) { 
        slideLoadingPromise.then(result => {
            _slideField.json = result.json;
            _slideField.hasData = result.json.soort === SlideSoort.Woordweb || result.json.soort === SlideSoort.LijstInvoer || result.json.soort === SlideSoort.LijstWeergave;
        });
    }
    function slideField(json) {
        _slideField.json = json;
        return _slideField;
    }

    return <div className="slide-page">
        <AsyncContent promise={slideLoadingPromise} render={(json) =>
            <SlideView slideField={slideField(json)} onPopup={setPopupContent} />}
        />
    </div>
}

export default SlidePage;