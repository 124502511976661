const NOT_AUTHORIZED = 'NOT_AUTHORIZED'

function buildRequest(authToken, fields) {
    let data = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    if (fields) {
        data.method = 'POST'
        data.body = JSON.stringify(fields)
    }
    if (authToken) {
        data.headers['X-AUTH-TOKEN'] = authToken
    }
    return data
}

export async function request(authToken, path, fields) {
    let data = buildRequest(authToken, fields)
    const response = await fetch(path, data)
    if (response.status === 401) {  // 401 = Unauthorized
        console.log('401 = Unauthorized')
        // return { json, success: false }
        throw Error(NOT_AUTHORIZED)
    } else if (!response.ok) {
        console.warn('server response not OK')
    }
    const json = await response.json()
    if (json.err === 'NOT_AUTHORIZED' || json.error === 'NOT_AUTHORIZED') {
        console.log('logged out (by server')
        // return { json, success: false }
        throw Error(NOT_AUTHORIZED)
    }
    if (json.err) throw json.err
    if (json.error) throw json.error
    if (json.message) throw json.message
    return { json, success: true }
}
