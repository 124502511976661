import React from 'react';
import './VideoFileSlideContent.scss';

const VideoFileSlideContent = ({ slide }) => {
    return <div className="video-file-slide">
        <video controls>
            <source src={`./iframe/mp4/${slide.tekst}`} type="video/mp4" />
            Je browser kan {slide.tekst} niet spelen.
        </video>
    </div>
}
export default VideoFileSlideContent;