import React, { useRef } from 'react';
import BoltImage from '../../bolt/BoltImage';
import './AfbeeldingenSlideContent.scss';

function hasValue(value) {
    return value !== null, value !== "" && typeof (value) !== "undefined";
}

const AfbeeldingSingle = ({ el, onPopup }) => {
    const elementRef = useRef();
    return <div className="afbeelding-slide">
        <div>
            <BoltImage info={el.afbeelding} />
        </div>
        <p>{el.ondertitel}</p>
        {hasValue(el.toelichting) && <button ref={elementRef} onClick={() => onPopup(
            <div className="popup-tekst" dangerouslySetInnerHTML={{ __html: el.toelichting }}></div>,
            elementRef
        )}></button>}
    </div>;
}

const Afbeelding = ({ el, onPopup }) => {
    const elementRef = useRef();
    return <div>
        <div>
            <BoltImage info={el.afbeelding} />
            {hasValue(el.toelichting) && <button ref={elementRef} onClick={() => onPopup(
                <div className="popup-tekst" dangerouslySetInnerHTML={{ __html: el.toelichting }}></div>,
                elementRef
            )}></button>}
        </div>
        <p>{el.ondertitel}</p>
    </div>;
}

const AfbeeldingenSlideContent = ({ slide, onPopup }) => {

    if (!hasValue(slide.afbeeldingen) || slide.afbeeldingen.length === 0) {
        return <div>Geen afbeeldingen.</div>
    }

    if (slide.afbeeldingen.length === 1) {
        const el = slide.afbeeldingen[0];
        return <AfbeeldingSingle el={el} onPopup={onPopup} />
    }

    return <div className={`afbeeldingen-slide ${slide.afbeeldingen_template}`}>
        {slide.afbeeldingen && slide.afbeeldingen.map((el, index) => (
            <Afbeelding key={index} el={el} onPopup={onPopup} />
        ))}
    </div>
}
export default AfbeeldingenSlideContent;