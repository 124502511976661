import React, { useRef } from 'react';

/*
  SR: Sorry voor de meest ranzige shit-code ooit.
      Mijn slechtste code in 20 jaar.
      Ga hier niet in pielen maar begin liever opnieuw!
      Wat waarschijnlijk een goed oplossing is, is om het
      hele tooltip verhaal los te trekken van react en er
      eoa mooie JS library voor te gebruiken.
*/
let g_Timer;
const Tooltip = ({ info, onClose }) => {
  const { content, elementRef } = info;
  const el = elementRef.current;
  if (!el) {
    return null;
  }
  // console.log('Tooltip render');
  const refWindow = useRef();
  const refArrow = useRef();

  clearInterval(g_Timer);
  g_Timer = setInterval(_repositionTooltip, 100);

  function _repositionTooltip() {

    if (!el) {
      clearInterval(g_Timer);
    }

    const windowEl = refWindow.current;
    const arrowEl = refArrow.current;

    if (!windowEl) return;
    if (!arrowEl) return;

    const windowRect = windowEl.getBoundingClientRect();
    const rect = el.getBoundingClientRect();
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const cx = rect.x + rect.width / 2;
    const cy = rect.y + rect.height / 2;

    const horizontal = Math.abs(cx - vw / 2) > Math.abs(cy - vh / 2);

    // console.log("[quadrant]", {
    //   innerHeight: window.innerHeight,
    //   clientHeight: document.documentElement.clientHeight
    // });

    // quadrant: eerste kijken of de tooltip rechts, links, boven of onder het element komt

    let quadrant = "center";
    if (cy > .8 * vh) {
      quadrant = "bottom";
    } else if (horizontal && cx < vw / 2) {
      quadrant = "left";
    } else if (horizontal && cx > vw / 2) {
      quadrant = "right";
    } else if (cy < vh / 2) {
      quadrant = "top";
    } else {
      quadrant = "bottom";
    }

    // bereken ideale positie voor tooltip window

    const windowPos = {
      left: Math.max(0, Math.round(cx - windowRect.width / 2)),
      top: Math.max(0, Math.round(cy - windowRect.height / 2))
    };

    if (quadrant === "left") {
      windowPos.left = Math.min(rect.right, vw - windowRect.width);
    } else if (quadrant === "right") {
      windowPos.left = Math.max(rect.left - windowRect.width, 0);
    } else if (quadrant === "top") {
      windowPos.top = Math.min(rect.bottom, vh - windowRect.height);
    } else if (quadrant === "bottom") {
      windowPos.top = Math.max(rect.top - windowRect.height, 0);
    }

    // limit
    const margin = 10;
    if (windowPos.left + windowRect.width > vw - margin) {
      windowPos.left = vw - margin - windowRect.width;
    }
    if (windowPos.left < margin) {
      windowPos.left = margin;
    }
    if (windowPos.top + windowRect.height > vh - margin) {
      windowPos.top = vh - margin - windowRect.height;
    }
    if (windowPos.top < margin) {
      windowPos.top = margin;
    }

    // pas window positie toe

    windowEl.style.left = windowPos.left + 'px';
    windowEl.style.top = windowPos.top + 'px';

    // juiste icoon voor pijltje

    arrowEl.className = `arrow ${quadrant}-arrow`;

    // restore css positie van pijltje

    arrowEl.style.left = 'initial';
    arrowEl.style.right = 'initial';
    arrowEl.style.top = 'initial';
    arrowEl.style.bottom = 'initial';

    if (quadrant === "left") {
      arrowEl.style.left = '-1rem';
    } else if (quadrant === "right") {
      arrowEl.style.right = '-1rem';
    } else if (quadrant === "top") {
      arrowEl.style.top = '-1rem';
    } else if (quadrant === "bottom") {
      arrowEl.style.bottom = '-1rem';
    }

    // centreer pijltje

    if (quadrant === "right" || quadrant === "left") {
      arrowEl.style.top = (cy - windowPos.top) + 'px';
    } else {
      arrowEl.style.left = (cx - windowPos.left) + 'px';
    }
  }

  return <div className="tooltip" onClick={onClose} ref={refWindow}>
    <div ref={refArrow} className="arrow"></div>
    <div>{content}</div>
  </div>;
}
export default Tooltip;