import React, { useEffect, useRef, useState } from 'react';
import './LijstInvoerSlideContent.scss';

/*
     node states:

     READ-ONLY / CENTER NODE : toon tekst, geen interactie
     TEKST + LINE : toon tekst, click om te editen
     EDIT : toon tekst in input veld
     NEW : toon (+)
     HIDDEN : toon helemaal niks

     NOTE:  dit is veel copy/paste van woordweb,
            misschien dat ze later grotendeels samen te voegen zijn

    verschillen:
        andere stylesheet
        MAX_NODES
        default waarde heeft geen start node
*/

const MAX_NODES = 3 * 8;

const NodeState = {
    READONLY: 0,
    VISIBLE: 1,
    EDITING: 2,
    ADD: 3,
    HIDDEN: 4
}

const Node = ({ info, onClick, onChange, onSubmit }) => {
    const refInput = useRef();

    useEffect(() => {
        if (refInput.current) {
            refInput.current.focus();
        }
    });

    let content;
    switch (info.state) {
        case NodeState.EDITING:
            content = <input ref={refInput} type="text"
                maxLength="255"
                value={info.text}
                onChange={e => onChange(e.target.value)}
                onKeyPress={e => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                        onSubmit();
                    }
                }}
                onBlur={onSubmit}
            />;
            break;
        case NodeState.VISIBLE:
            content = <span onClick={onClick}>{info.text}</span>
            break;
        case NodeState.READONLY:
            content = <span>{info.text}</span>;
            break;
        case NodeState.ADD:
            content = <button onClick={onClick}>+</button>
            break;
        default:
            return null;
    }
    return content;
}

const LijstInvoerSlideContent = ({ slideField }) => {
    const slide = slideField.json;
    const storageKey = `lijst ${slide.tekst}`;
    const storageSave = _nodes => window.sessionStorage.setItem(storageKey, JSON.stringify(_nodes));
    const storageLoad = () => window.sessionStorage.getItem(storageKey);

    const defaultValue = [
        { text: '', state: NodeState.ADD }
    ];
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        let initialValue = storageLoad();
        initialValue = initialValue ? JSON.parse(initialValue) : defaultValue
        setNodes(initialValue);
    }, [storageKey]);

    const setNode = (index, fn) => {
        let newNodes = [...nodes];
        newNodes[index] = fn(newNodes[index]);
        setNodes(newNodes);
    }

    const changeNode = (array, index, fn) => {
        array[index] = fn(array[index]);
    }

    const onChangeNode = (index, value) => {
        setNode(index, el => ({ text: value, state: el.state }));
    }

    const onSubmitNode = (index) => {
        let newNodes = [...nodes];
        changeNode(newNodes, index, el => ({ text: el.text, state: NodeState.VISIBLE }));

        if (newNodes.length < index + 2 && newNodes.length < MAX_NODES) {
            newNodes.push({ text: '', state: NodeState.ADD });
        }

        setNodes(newNodes);
        storageSave(newNodes);

        slideField.setIsChanged(true);
    }

    slideField.onImport = data => {
        const newNodes = [
            ...data.map(text => ({ text, state: NodeState.VISIBLE })),
            { text: '', state: NodeState.ADD }
        ].slice(0, MAX_NODES);
        setNodes(newNodes);
        storageSave(newNodes);

        slideField.setIsChanged(false);
    }

    slideField.onExport = () => nodes.map(el => el.text);

    const onClickNode = (index) => {
        switch (nodes[index].state) {
            case NodeState.ADD:
            case NodeState.VISIBLE:
                setNode(index, el => ({ text: el.text, state: NodeState.EDITING }));
                break;
        }
    }
    return <div className="lijst-invoer-slide">
        {nodes.map((el, index) => <Node key={index}
            info={el}
            onClick={() => onClickNode(index)}
            onChange={(value) => onChangeNode(index, value)}
            onSubmit={() => onSubmitNode(index)}
        />)}
    </div>
}
export default LijstInvoerSlideContent;
