import React from 'react';

/*
    info:
    
    {
        url: string,
        html: html,
        responsive: html,
        response_inline: html,
    }

*/
const BoltVideo = ({info}) => {
    return info && info.url !== ""
        ? <div className="bolt-video" dangerouslySetInnerHTML={{ __html: info.responsive}} ></div>
        : <div>[Geen video gekozen.]</div>;
}
export default BoltVideo;
