import React from 'react';
import BoltImage from '../../bolt/BoltImage';
import BoltVideo from '../../bolt/BoltVideo';

const DebugSlideContent = ({ slide }) => {
    return <div>
        <h2><tt>{slide.soort}</tt></h2>
        <fieldset>
            <legend>Tekst</legend>
            <h2>{slide.tekst}</h2>
        </fieldset>
        <fieldset>
            <legend>Extra Aandacht?</legend>
            {slide.aandacht ? "Ja" : "Nee"}
        </fieldset>
        <fieldset>
            <legend>Afbeelding</legend>
            <BoltImage info={slide.afbeelding} />
        </fieldset>
        <fieldset>
            <legend>Video</legend>
            <BoltVideo info={slide.video} />
        </fieldset>
        <fieldset>
            <legend>Inhoud</legend>
            <div dangerouslySetInnerHTML={{ __html: slide.inhoud }}></div>
        </fieldset>
    </div>
}
export default DebugSlideContent;