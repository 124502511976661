import React from 'react';
import { SlideSoort } from '../../services/backend';
import AfbeeldingenSlideContent from './afbeeldingen/AfbeeldingenSlideContent';
import DebugSlideContent from './debug/DebugSlideContent';
import EmbedSlideContent from './embed/EmbedSlideContent';
import InteractiveSlideContent from './interactive/InteractiveSlideContent';
import LijstInvoerSlideContent from './lijst_invoer/LijstInvoerSlideContent';
import LijstWeergaveSlideContent from './lijst_weergave/LijstWeergaveSlideContent';
import TekstSlideContent from './tekst/TekstSlideContent';
import TekstAfbeeldingSlideContent from './tekst_afbeelding/TekstAfbeeldingSlideContent';
import VideoSlideContent from './video/VideoSlideContent';
import WoordWebSlideContent from './woordweb/WoordWebSlideContent';

import './SlideView.scss';
import VideoFileSlideContent from './videofile/VideoFileSlideContent';

const SlideContent = ({ slideField, onPopup }) => {
    switch (slideField.json.soort) {
        case SlideSoort.Tekst:
            return <TekstSlideContent slide={slideField.json} onPopup={onPopup} />;
        case SlideSoort.Afbeeldingen:
            return <AfbeeldingenSlideContent slide={slideField.json} onPopup={onPopup} />;
        case SlideSoort.Video:
            return <VideoSlideContent slide={slideField.json} onPopup={onPopup} className="fit-height" />;
        case SlideSoort.VideoFile:
            return <VideoFileSlideContent slide={slideField.json} onPopup={onPopup} className="fit-height" />;
        case SlideSoort.AfbeeldingTekst:
            return <TekstAfbeeldingSlideContent slide={slideField.json} onPopup={onPopup} />;
        case SlideSoort.Woordweb:
            return <WoordWebSlideContent slideField={slideField} onPopup={onPopup} />;
        case SlideSoort.LijstInvoer:
            return <LijstInvoerSlideContent slideField={slideField} onPopup={onPopup} />;
        case SlideSoort.LijstWeergave:
            return <LijstWeergaveSlideContent slideField={slideField} onPopup={onPopup} />;
        case SlideSoort.Interactive:
            return <InteractiveSlideContent slide={slideField.json} onPopup={onPopup} />;
        case SlideSoort.Embed:
            return <EmbedSlideContent slide={slideField.json} onPopup={onPopup} />;
        default:
            return <DebugSlideContent slide={slideField.json} onPopup={onPopup} />;
    }
}

const SlideView = ({ slideField, onPopup }) => {
    return <div className={`slide-view ${slideField.json.soort}`}>
        <div className="slide-title">
            <h3>{slideField.json.titel}</h3>
            <h1>{slideField.json.subtitel}</h1>
        </div>
        {/* <div className="system-text">
            {slideField.json.code}
        </div> */}
        <SlideContent slideField={slideField} onPopup={onPopup} />
    </div>
}

export default SlideView;