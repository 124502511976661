import React from 'react';

/*
    info:
    
    {
        "filename": "",
        "path": "",
        "media": "",
        "thumbnail": "",
        "fieldname": "afbeelding",
        "alt": "",
        "url": "",
        "extension": ""
    }

*/
const BoltImage = ({ info, thumbnail }) => {
    if( info ) {
        const url = `${__BACKEND__.files}${thumbnail ? info.thumbnail : info.path}`;
        return <img src={url} alt={info.alt} />;
    } else {
        return <span>[Geen afbeelding gekozen.]</span>;
    }
}
export default BoltImage;
