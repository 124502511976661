import React, { useState } from 'react';
import './LijstWeergaveSlideContent.scss';

const LijstWeergaveSlideContent = ({ slideField }) => {
    const slide = slideField.json;
    const storageKey = `${slide.tekst}`;
    const storageLoad = () => window.sessionStorage.getItem(storageKey);

    let initialValue = storageLoad();
    initialValue = initialValue ? JSON.parse(initialValue) : [];

    const [nodes, setNodes] = useState(initialValue);

    slideField.onImport = data => {
        setNodes(data);
        storageSave(data);
    }

    slideField.onExport = () => nodes;

    slideField.onImport = data => {
        const newNodes = [
            ...data.map(text => ({ text, state: 1 }))   // 1 = NodeState.VISIBLE
        ];
        setNodes(newNodes);
        // storageSave(newNodes);
    }

    slideField.onExport = () => nodes.map(el => el.text);

    return <div className="lijst-weergave-slide">
        {nodes.map((el, index) => <span key={index}>
            {el.text}
        </span>)}
    </div>
}
export default LijstWeergaveSlideContent;
