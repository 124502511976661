import React, { useEffect, useRef, useState } from 'react';
import { getSlide, SlideSoort } from '../../../services/backend';
import AsyncContent from '../../common/AsyncContent';
import PopupButtons from '../../slide/PopupButtons';
import SlideView from '../../slide/SlideView';
import FullScreenButton from '../../common/FullScreenButton';
import SmallLogo from '../../../assets/ui/logo_maakkunde_blauw.svg';
import SlideExportImport from './SlideImportExport';
import ChapterThumb from './ChapterThumb';
import Tooltip from './Tooltip';
import CloseIcon from '../../../assets/ui/iconClose_wit.svg';
import { CONFIRM_LEAVE_PAGE } from '../../../services/constants';

import './Browser.scss';

const SideBar = ({ json, chapterIndex, allowScroll, logolink }) => {

    const upArrowRef = useRef(null);
    const downArrowRef = useRef(null);
    const thumbnailsRef = useRef(null);
    const downIndicatorRef = useRef(null);
    const upIndicatorRef = useRef(null);

    if (allowScroll) {

        useEffect(() => {

            let delta = 0;
            let timerID = null;

            function animate() {
                thumbnailsRef.current.scrollTop += delta;

                const maxScrollTop = (thumbnailsRef.current.scrollHeight - thumbnailsRef.current.getBoundingClientRect().height);
                upIndicatorRef.current.style.visibility = (thumbnailsRef.current.scrollTop <= 0 ? 'hidden' : 'visible');
                downIndicatorRef.current.style.visibility = (thumbnailsRef.current.scrollTop > maxScrollTop - 1 ? 'hidden' : 'visible');
            }

            function scroll(b, d) {
                delta = d;
                clearInterval(timerID);
                if (b) {
                    timerID = setInterval(animate, 1000 / 60);
                }
            }

            animate();

            upArrowRef.current.addEventListener("mousedown", e => {
                scroll(true, -10);
            });
            upArrowRef.current.addEventListener("mouseup", e => {
                scroll(false, 0);
            });
            upArrowRef.current.addEventListener("mouseleave", e => {
                scroll(false, 0);
            });
            downArrowRef.current.addEventListener("mousedown", e => {
                scroll(true, 10);
            });
            downArrowRef.current.addEventListener("mouseup", e => {
                scroll(false, 0);
            });
            downArrowRef.current.addEventListener("mouseleave", e => {
                scroll(false, 0);
            });

        }, []);

    }

    return <aside>
        <a href={logolink}>
            <img src={SmallLogo} />
        </a>
        <hr />
        {allowScroll ? <div ref={upIndicatorRef} className="icon up-arrow"></div> : <div>&nbsp;</div>}
        <div ref={thumbnailsRef} id="thumbnails">
            {json.chapters.map((chapter, index) =>
                <ChapterThumb key={index} index={index} chapter={chapter} selected={index === chapterIndex} />)}
        </div>
        {allowScroll && <>
            <div ref={downIndicatorRef} className="icon down-arrow"></div>
            <hr />
            <div id="scroll-buttons">
                <button ref={upArrowRef} className="up-arrow icon-button secondary"></button>
                <button ref={downArrowRef} className="down-arrow icon-button secondary"></button>
            </div>
        </>}
    </aside>;
}

const Browser = ({ json, path }) => {

    if (!json || !json.chapters || json.chapters.length === 0) {
        return <div>Geen hoofdstukken. <a href="/">HOME</a></div>;
    }

    const [p_chapter, p_slide] = path.substr(1).split('/');
    const chapterIndex = p_chapter ? parseInt(p_chapter) : 0;
    const slideIndex = p_slide ? parseInt(p_slide) : 0;
    const chapter = json.chapters[chapterIndex];
    const slide = chapter.slides[slideIndex];
    const prevLink = slideIndex > 0
        ? `#${p_chapter}/${slideIndex - 1}`         // prev slide in this chapter
        : chapterIndex > 0
            ? `#${chapterIndex - 1}/${json.chapters[chapterIndex - 1].slides.length - 1}`       // last slide previous chapter
            : '';
    const nextLink = slideIndex < chapter.slides.length - 1
        ? `#${p_chapter}/${slideIndex + 1}`
        : chapterIndex < json.chapters.length - 1
            ? `#${chapterIndex + 1}/0`
            : '';
    const allowPrev = prevLink !== '';
    const allowNext = nextLink !== '';
    const [tooltipContent, setTooltipContent] = useState();
    const [popupContent, setPopupContent] = useState();
    const slideLoadingPromise = slide ? getSlide(slide.id) : null;
    const allowScroll = false; // son.chapters.length > 5;
    const wipeRef = useRef();

    const [isChanged, setIsChanged] = useState();
    const _slideField = { isChanged, setIsChanged };
    if (slide) {
        slideLoadingPromise.then(result => {
            _slideField.json = result.json;
            _slideField.hasData = result.json.soort === SlideSoort.Woordweb || result.json.soort === SlideSoort.LijstInvoer || result.json.soort === SlideSoort.LijstWeergave;
        });
    }
    function slideField(json) {
        _slideField.json = json;
        return _slideField;
    }

    function goWithTransition(href, name) {

        // clear tooltip
        setTooltipContent();

        if (!_slideField.isChanged || window.confirm(CONFIRM_LEAVE_PAGE)) {

            _slideField.setIsChanged(false);

            const PAGE_LOAD_TIME = 500; // milliseconds
            wipeRef.current.className = "";
            wipeRef.current.classList.add(name + "-in");
            setTimeout(() => {
                window.location.href = href;
            }, 200);
            setTimeout(() => {
                wipeRef.current.className = "";
                wipeRef.current.classList.add(name + "-out");
            }, 200 + PAGE_LOAD_TIME);
            setTimeout(() => {
                wipeRef.current.className = "";
            }, 400 + PAGE_LOAD_TIME);

        }
    }

    function prevClicked() {
        goWithTransition(prevLink, "scroll-down");
    }

    function nextClicked() {
        goWithTransition(nextLink, "scroll-up");
    }

    function setTooltip(content, elementRef) {
        if (tooltipContent) {
            if (elementRef === tooltipContent.elementRef) {
                // toggle? close!
                setTooltipContent();
                return;
            }
        }
        setTooltipContent({ content, elementRef });
    }

    return <div className="Browser">
        {tooltipContent && <Tooltip info={tooltipContent} onClose={() => setTooltipContent()} />}
        {popupContent &&
            <div className="popup">
                <div onClick={() => setPopupContent()}></div>
                <div>{popupContent}</div>
                <img src={CloseIcon} />
            </div>}
        <div className="top-right-float">
            <FullScreenButton />
        </div>
        <SideBar json={json} chapterIndex={chapterIndex} allowScroll={allowScroll} logolink="#" />
        <main>
            <div id="content" ref={wipeRef}>
                {/*<h2>{chapter.titel}</h2>*/}
                {slide && <AsyncContent promise={slideLoadingPromise} render={(json) =>
                    <SlideView slideField={slideField(json)} onPopup={setTooltip} />} />}
            </div>
            <footer>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    {slide && <AsyncContent promise={slideLoadingPromise} render={(json) =>
                        <SlideExportImport slideField={slideField(json)} />} />}
                    {slide && <AsyncContent promise={slideLoadingPromise} render={(json) =>
                        <PopupButtons slide={json} onTooltip={setTooltip} onPopup={setPopupContent} />} />}
                    <button onClick={prevClicked} className={`button left-icon up-arrow ${allowPrev ? "" : "disabled"}`}>Terug</button>
                    {allowNext && <button onClick={nextClicked} className={`button left-icon down-arrow ${allowNext ? "" : "disabled"}`}>Verder</button>}
                    {!allowNext && <a href={`?lesson=${json.id}&mode=end`} className="button primary">Les Afronden</a>}
                </div>
            </footer>
        </main>
        <div id="indicators">
            {chapter.slides.map((el, index) => index === slideIndex ? <b key={index} /> : <i key={index} />)}
        </div>
    </div>
}
export default Browser;