import React, { useState } from 'react';
import { getLesson } from '../../services/backend';
import AsyncContent from '../common/AsyncContent';
import Browser from './browser/Browser';
import Frontpage from './frontpage/Frontpage';

const LessonPage = ({ lesson, mode }) => {

    let [path, setPath] = useState(window.location.hash);
    window.onhashchange = () => {
        setPath(window.location.hash);
    };

    return <AsyncContent promise={getLesson(lesson)} render={(json) =>
        path
            ? <Browser json={json} path={path} />
            : <Frontpage json={json} mode={mode} />
    } />;
}
export default LessonPage;